import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from "atoms";
import { HomeContactCard, HomeImageSlider, HomeReviewSlider, NavCard, PopUpModal } from "molecules";
import { appConstants } from "redux/constants";
import { generatePublicURL } from "utils/utilities";
import { HomeInfo, HomeKnowlegdeResources, HomeNewsEvents, HomeResources } from "./molecules";

export const HomePage = () => {   

  const dispatch = useDispatch();
  const { home, popUp } = useSelector(state => state.app); 
  const [ delayPopUp, setDelayPopUp ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDelayPopUp(true);
    }, 5000);
  }, []);

  return (
    <Box overflow="hidden"> 
      {(popUp && home?.popUp?.show && delayPopUp) && <PopUpModal
        isOpen={(popUp && home?.popUp?.show && delayPopUp)}
        onRequestClose={() => dispatch({type: appConstants.CLOSE_POP_UP})}
        ariaHideApp={false}
        title={home?.popUp?.title}
        image={generatePublicURL(home?.popUp?.image?.url)}
        links={home?.popUp?.links}
      />}                          
      {/* IMAGE SLIDER */}
      {home?.slider && <HomeImageSlider data={home?.slider}/>}
      {home?.homeAbout && <HomeInfo
        title={home?.homeAbout?.title}
        desc={home?.homeAbout?.description?.split("<br/>")}
        image={generatePublicURL(home?.homeAbout?.image?.url)}
        linkLabel={home?.homeAbout?.linkLabel}
        linkUrl={home?.homeAbout?.linkUrl}
        videoUrl={home?.homeAbout?.videoUrl}
      />} 
      {/* NAVCARDS */}
      {home?.featured && <Grid
        gridTemplateColumns={{xs: "1fr", md: "1fr 1fr 1fr"}}
        gridGap="1.5rem"
        width="100%" 
        maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
        m="5rem auto"            
        justifyItems="center"  
      >
        {home?.featured?.map((item) => (
          <NavCard
            key={item?.id}
            image={generatePublicURL(item?.image?.url)}
            title={item?.title}
            description={item?.description}
            linkText={item?.btnLabel}
            href={item?.btnUrl}            
          />
        ))}
      </Grid>}  
      {/* NEWS AND EVENTS */}
      <HomeNewsEvents 
        news={home?.news}
        events={home?.events}
      />
      {/* RESOURCES SECTION */}
      {home?.resources && <HomeResources resources={home?.resources} />}
      {/* HOME INFO SECTION */}
      {home?.moreInfo?.length > 0 && home?.moreInfo?.map((item) => (
        <HomeInfo
          key={item?.id}
          title={item?.title}
          desc={item?.description?.split("<br/>")}
          image={item?.image ? generatePublicURL(item?.image?.url) : ""}
          linkLabel={item?.linkLabel}
          linkUrl={item?.linkUrl}
          videoUrl={item?.videoUrl}
        />      
      ))} 
      {/* REVIEW SLIDER */}
      <Box
        maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
        mx="auto"            
        mt={{xs: "5rem", md: "10rem"}}
        mb="5rem"
      >
        {home?.testimonials && <HomeReviewSlider reviews={home?.testimonials}/>}
      </Box>
      {home?.knowledgeResources && <HomeKnowlegdeResources
        data={home?.knowledgeResources}
      />}
      {/* CONTACT CARD */}
      <HomeContactCard/>
    </Box>
  );
};