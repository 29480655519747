import { useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { MdOutlineArrowForward } from "react-icons/md";
import { Controls, Player } from "@lottiefiles/react-lottie-player";

import NewAnimation from "assets/animations/new.json";
import { Box, Flex, Text } from "atoms";
import { GetLink } from "molecules";

export const ResourceCard = ({
  title,
  links
}) => {

  const [ open, setOpen ] = useState(false);

  return (
    <Box 
      width="100%" 
      borderBottom="1px solid"
      borderColor="grey.100"
      height="fit-content"
    >
      <Flex
        py="2rem"        
        width="100%"
        alignItems="center" 
        onClick={() => setOpen(!open)}               
        cursor="pointer" 
      >
        <Flex
          width="5rem"
          height="5rem"     
          borderRadius="50%"
          color="white"  
          bg={open ? "primary.500" : "grey.100"}          
          alignItems="center"
          justifyContent="center"          
          _hover={{
            bg: "primary.500",            
          }}           
          transition="all 300ms ease-in-out"
          transform={open ? "rotate(90deg)" : "rotate(0deg)"}          
        >
          <MdOutlineArrowForward
            fontSize="3rem"
          />
        </Flex>
        <Text
          ml="1.5rem"
          flexGrow="1"
          fontSize="1.8rem"
          fontWeight="500"
          color="grey.300"
        >
          {title}
        </Text>
      </Flex>
      {open && <Box
        transition="height 300ms ease-in-out"
        pl="6rem"
        pb="2rem"
      >
        {links?.map((link) => (          
          <GetLink
            url={link.url}
            key={link?.id}
          >
            <Flex                
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              pb="1rem"
              color={link?.new ? "primary.100" : "grey.500"}
              fontWeight={link?.new ? "600" : "500"}
              _hover={{
                color: "primary.500",              
              }}
              transition="all 300ms ease-in-out"
              cursor="pointer"
              fontSize="1.6rem"
            >
              <Flex alignItems="center">
                <Box>            
                  <FiExternalLink/>
                </Box>
                <Text mt="-0.4rem" ml="0.5rem">
                  {link?.title}
                </Text>
              </Flex>
              {link?.new && <Box
                width="3rem"              
              >
                <Player autoplay loop src={NewAnimation}>
                  <Controls visible={false} />
                </Player>
              </Box>}
            </Flex>
          </GetLink>          
        ))}
      </Box>}
    </Box>
  )
}