import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Text } from "atoms";
import { getCourseDetails } from "redux/actions";
import SEO from "seo/SEO";
import { CourseDetailsPage } from "templates";
import { deSlugAndCapitalize, scrollToTop } from "utils/utilities";

const CourseDetails = () => {
  
  const dispatch = useDispatch();
  const { courseDetails } = useSelector(state => state.data);
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getCourseDetails(slug));
    scrollToTop(window); 
  }, [dispatch, slug])

  return (
    <>
      <SEO
        title={`${courseDetails?.data?.slug === slug ?  courseDetails?.data?.title : deSlugAndCapitalize(slug)} | Soarghaati Law College, Pithoragarh`}
        description={courseDetails?.data?.description || "Soarghaati Law College, Pithoragarh"}
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/course/${slug}`}        
      />
       {courseDetails?.data?.slug !== slug ?
        <Text bg="dark.100" color="white" py="3rem" textAlign="center">Course Not Found</Text>                
      : <CourseDetailsPage
        course={courseDetails}
      />}        
    </>
  )
}

export default CourseDetails;