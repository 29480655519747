import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getAboutPage } from "redux/actions";
import { AboutPage } from "templates";
import { scrollToTop } from "utils/utilities";

export const About = () => {

  const { type } = useParams();  
  const dispatch = useDispatch();
  const { aboutPage, loading } = useSelector(state => state.data);  

  useEffect(() => {
    if(!aboutPage)  dispatch(getAboutPage());    
  }, [dispatch, aboutPage]);

  useEffect(() => {
    scrollToTop(window);
  }, [type]);

  return (
    <>                 
      <AboutPage
        aboutData={aboutPage}
        type={type}
        loading={loading}
      />
    </>
  );
};

export default About;
