import { useEffect } from "react";

import SEO from "seo/SEO";
import { EventPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Events = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Events | Soarghaati Law College, Pithoragarh"
        description="Events of Soarghaati Law College, Pithoragarh"
        keywords="Events SGLC, Events SG Law College, Events Soarghaati Law College, Pithoragarh, SEvents Soarghaati Law College"        
      />      
      <EventPage/>
    </>
  )
}

export default Events;