import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { EventCard, PageSearchLayout } from "molecules";
import { getEvents } from "redux/actions";

export const EventPage = () => {  

  const dispatch = useDispatch();
  const { events } = useSelector(state => state.data);
  
  const [ search, setSearch ] = useState("");
  const [ page, setPage ] = useState(1);  
  
  useEffect(() => {    
    dispatch(getEvents(
      page,
      12,
      search    
    ));
  }, [page, search, dispatch]);   

  return (
    <>      
      <PageSearchLayout
        heroData={{
          image: "/images/home-contact.jpg",
          breadcrumb: [
            {label: "Home", url: "/"},
            {label: "Events", url: "/events"}
          ]
        }}
        search={search}
        setSearch={setSearch}
        totalCount={events?.meta?.pagination?.total}
        resultPerPage={events?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No events found"
      >    
        <Grid                        
          gridTemplateColumns={{xs: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}}
          gridGap="2.5rem"
          pb="2rem"            
        >
          {events?.data?.map((event) => (
            <EventCard
              key={event?.id}
              timestamp={event?.attributes?.date}
              title={event?.attributes?.title}
              slug={event?.attributes?.slug}
            />
          ))}
        </Grid>                         
      </PageSearchLayout>
    </>
  )
}