import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { PageSearchLayout, ProfileCard } from "molecules";
import { getFaculty } from "redux/actions";
import { designations } from "utils/constant-data";
import { generatePublicURL } from "utils/utilities";

export const FacultyStaffPage = ({
  type,
  title
}) => {

  const dispatch = useDispatch();
  const { faculty } = useSelector(state => state.data);
  const [ search, setSearch ] = useState("");
  const [ page, setPage ] = useState(1);
 
  useEffect(() => {    
    dispatch(getFaculty(
      page,
      12,
      search,
      type
    ));    
  }, [search, page, dispatch, type]);  

  return (
    <>
      <PageSearchLayout
        heroData={{
          image: "/images/home-contact.jpg",
          breadcrumb: [
            {label: "Home", url: "/"},
            {label: title, url: `/faculty/faculty-staff-directory/${type}`}
          ]
        }}
        search={search}
        setSearch={setSearch}
        totalCount={faculty?.meta?.pagination?.total}
        resultPerPage={faculty?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No faculties found"
      >
        <Grid
          gridTemplateColumns={{xs: "1fr", md: "repeat(2, 1fr)"}}
          gridGap="2.5rem"        
        >
          {faculty?.data?.map((profile) => (
            <ProfileCard
              key={profile?.id}
              name={profile?.attributes?.name}
              designation={designations?.find((designation) => designation?.value === profile?.attributes?.designation)?.label || ""}
              qualification={profile?.attributes?.qualifications}
              specification={profile?.attributes?.specialization}
              email={profile?.attributes?.email || []}
              phone={profile?.attributes?.phone || []}
              image={generatePublicURL(profile?.attributes?.image?.data?.attributes?.url)}
              profileUrl={profile?.attributes?.profile?.data?.attributes?.url ? generatePublicURL(profile?.attributes?.profile?.data?.attributes?.url) : null}            
            />
          ))}          
        </Grid>
      </PageSearchLayout>
    </>
  )
}