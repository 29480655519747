import { useEffect } from "react";

import SEO from "seo/SEO";
import { HomePage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Home = () => {
  
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>      
      <SEO
        title="Soarghaati Law College, Pithoragarh | Best Emerging Law College in the Himalayas of Uttrakhand, India"
        description="Soarghaati Law College is an emerging law college in the Himalayas of Uttrakhand, India. Soarghaati Law College is known for its best legal education system and  law college courses."
        keywords="SGLC, SG Law College, Soarghaati Law College, Pithoragarh, Soarghaati Law College"        
      /> 
      <HomePage/>
    </>
  );
};

export default Home;
