import React from "react";
import { BsFillClockFill } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";

import { Box, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const CourseCard = ({
  id,
  slug,
  image,
  title,
  description,
  time,
  seats,
  shadow=false
}) => {
  return (
    <Box
      p={{xs: "0.6rem", md: "1.2rem"}}
      height="100%"
    >
      <Box
        width="100%"
        height={{xs: "24rem", md: "28rem"}}
      >
        <Image
          src={image}
          alt={title}
          width="100%"
          height="100%"
          objectFit="cover"
        />
      </Box>
      <Box
        p="2rem"
        bg="white"
        height={{ xs: "fit-content", md: "calc(100% - 28rem)" }}
        boxShadow={shadow ? "0 0 20px 3px rgba(0, 0, 0, 0.05)" : "none"}
      >
        <Box
          width="fit-content"
        >
          <GetLink
            url={`/academics/courses/${slug}`}
          >
            <Text             
              fontSize={{xs: "2rem", md: "2.4rem"}}
              fontWeight="800"
              color="primary.500"          
              mb="0.8rem"
              _hover={{
                color: "secondary.500"
              }}
            >
              {title}
            </Text>
          </GetLink>
        </Box>
        <Text          
          fontSize="1.6rem"
          fontWeight="500"
          color="#707070"
          className="text-justify"
        >
          {description}
        </Text>

        <Flex
          mt="2rem"
          alignItems="center"
          style={{gap: "2rem"}}
          flexWrap="wrap"
        >
          {time && <CourseFeature
            Icon={<BsFillClockFill/>}
            label={time}
          />}
          {seats && <CourseFeature
            Icon={<HiUsers/>}
            label={seats}
          />}
        </Flex>
      </Box>
    </Box>
  )
}

const CourseFeature = ({
  Icon,
  label
}) => (
  <Flex
    alignItems="center"
    color="secondary.500"
  >
    <Flex
      alignItems="center"
      justifyContent="center" 
      mr="0.8rem"     
      fontSize="1.6rem"
    >
      {Icon}
    </Flex>
    <Text      
      fontSize="1.6rem"
      fontWeight="500"      
    >
      {label}
    </Text>
  </Flex>
)