import { useEffect } from "react";

import SEO from "seo/SEO";
import { AlbumsPage } from "templates";
import { scrollToTop } from "utils/utilities";

function Albums() {
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Albums | Soarghaati Law College, Pithoragarh"
        description="Albums of Soarghaati Law College, Pithoragarh"
        keywords="Albums SGLC, Albums SG Law College, Albums Soarghaati Law College, Pithoragarh, Gallery Soarghaati Law College"        
      />  
      <AlbumsPage/>      
    </>
  )
}

export default Albums;