import { useEffect } from "react";

import SEO from "seo/SEO";
import { NewsPage } from "templates";
import { scrollToTop } from "utils/utilities";

const News = () => {
  useEffect(() => {
    scrollToTop(window);
  }, [])

  return (
    <>
      <SEO
        title="Recent News | Soarghaati Law College, Pithoragarh"
        description="Recent News of Soarghaati Law College, Pithoragarh"
        keywords="Recent News SGLC, Recent News SG Law College, Recent News Soarghaati Law College, Pithoragarh, SRecent News Soarghaati Law College"        
      />      
      <NewsPage/>
    </>
  )
}

export default News;