import { useEffect } from "react";

import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";
import { CoursesPage } from "templates";

const Courses = () => {
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Academic Programs | Soarghaati Law College, Pithoragarh"
        description="Academic Programs of Soarghaati Law College, Pithoragarh"
        keywords="Academic Programs SGLC, Academic Programs SG Law College, Academic Programs Soarghaati Law College, Pithoragarh, SAcademic Programs Soarghaati Law College"        
      />  
      <CoursesPage/>       
    </>
  )
}

export default Courses;