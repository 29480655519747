import React from "react";
import { FadeLoader } from "react-spinners"

import { Box, Flex } from "atoms";

export const Loader = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="999"    
      animation="fadeIn 0.5s;"       
    >
      <Flex 
        width="100%"
        height="100%"        
        alignItems="center"
        justifyContent="center"  
        backgroundColor="rgba(0, 0, 0, 0.6)"      
      >
        <FadeLoader
          color={"#fff"}
          // size={50}
        />        
      </Flex>
    </Box>
    )
}