import { Flex, Text } from "atoms";
import { GetLink } from "molecules";
import { formatDate } from "utils/utilities";

export const EventCard = ({
  timestamp,
  title,
  slug
}) => {

  let time = formatDate(timestamp);

  return (
    <GetLink url={`/event/${slug}`}>
      <Flex minWidth="32rem">
        <Flex          
          bg="primary.500"
          color="white"
          p="1.4rem 1rem"
          minWidth="10rem"
          alignItems="center"
          flexDirection="column"
          mr="1rem"         
        >
          <Text fontSize="0.8rem">
            {time?.split(" ")[1]}
          </Text>
          <Text fontSize="2.6rem">
            {time?.split(" ")[2].substring(0, time?.split(" ")[2].length-1)}
          </Text>
          <Text fontSize="0.8rem">
            {time?.split(" ")[3]}
          </Text>          
        </Flex>
        <Text 
          fontSize="1.4rem"
          _hover={{color: "primary.500"}}
        >
          {title?.substring(0, 120)}
        </Text>
      </Flex>
    </GetLink>
  )
}