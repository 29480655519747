import React from "react";

import { Box, Grid, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const HomeInfo = ({
  title,
  desc,
  image,
  linkLabel,
  linkUrl,  
}) => {
  return (
    <Box
      maxWidth="120rem"
      m="6rem auto"
      px={{xs: "2rem", md: "4rem", lg: "0"}}            
    >
      <Grid
        gridTemplateColumns={{xs: "1fr", xm: "1fr 0.6fr"}}
        gridGap="1.5rem"
      > 
        <Box>
          {title && <Text            
            fontSize="2.4rem"
            fontWeight="600"
            mb="1.2rem"
            color="primary.500"
          >
            {title}
          </Text>}
          {desc?.length > 0 && desc?.map((item, index) => (
            <Text
              key={index}
              fontSize="1.4rem"
              mb="0.8rem"
              color="black"
              opacity="0.6"
              fontWeight="400" 
              lineHeight="1.6" 
              className="text-justify"            
            >
              {item}
              {(index === desc?.length -1 &&  linkLabel) && <Text
                as="span"
                color="accent.100"
                ml="0.5rem"
                fontWeight="400"
                fontSize="1.4rem"
              >
                <GetLink
                  url={linkUrl || ""}
                >
                  ...{linkLabel}
                </GetLink>
              </Text>}
            </Text>
          ))}
        </Box>

        <Box
          height="24rem"          
        >
          {image && <Image
            src={image}
            alt={title}
            width="100%"
            height="100%"
            objectFit="contain"             
          />}          
        </Box>
      </Grid>
    </Box>
  )
}