import { useEffect } from "react";

import SEO from "seo/SEO";
import { ResultsPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Results = () => {
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Download Results | Soarghaati Law College, Pithoragarh"
        description="Download Results of Soarghaati Law College, Pithoragarh"
        keywords="Download Results SGLC, Download Results SG Law College, Download Results Soarghaati Law College, Pithoragarh, SDownload Results Soarghaati Law College"        
      />   
      <ResultsPage/>
    </>
  )
}

export default Results;