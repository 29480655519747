import React from "react";
import { Formik, Form } from "formik";
import { BiEnvelope } from "react-icons/bi";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Input, Text } from "atoms";
import { sendContactMail } from "redux/actions";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid format")
    .required("Email Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),
  name: Yup.string()
    .required("Name Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),  
  message: Yup.string().max(2000, "Max 2000 characters allowed"),
});

export const ContactForm = () => {

  const dispatch = useDispatch();  
  const { loading } = useSelector(state => state.app);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    const form = {
      name: values.name,
      email: values.email,      
      message: values.message,      
    }           
    dispatch(sendContactMail(form));       
    setSubmitting(false);    
  }

  return (
    <>      
      <Box 
        maxWidth="100%" 
        bg="grey.50" 
        px={{xs: "3rem", md: "6rem", xm: "4rem"}}
        py={{xs: "5rem", md: "6rem", xm: "4rem"}}
      >
        <Text
          fontSize="2rem"
          fontWeight="600"
          mb="1rem"
        >
          Contact Form
        </Text>
        <Text
          fontSize="1.6rem"
          fontWeight="400"
          color="grey.200"
          mb="3rem"
        >
          Having a query? Feel free to Contact Us
        </Text>
        <Formik
          initialValues={{
            name: "",            
            email: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ touched, errors }) => {
            return (
              <Form>
                <Input                  
                  name="name"
                  type="text"
                  placeholder="Name*"                  
                  id="name"
                  icon={<FaUserAlt />}
                  iconColor={errors.name ? "danger.100" : "primary.500"}
                  touched={touched.name}
                  errors={errors.name}      
                  lessMB                              
                />  
                <Input                  
                  name="email"
                  type="email"
                  placeholder="Email*"                  
                  id="email"
                  icon={<BiEnvelope />}
                  iconColor={errors.email ? "danger.100" : "primary.500"}
                  touched={touched.email}
                  errors={errors.email}      
                  lessMB                              
                />                          
                <Input                  
                  name="message"
                  type="text"
                  placeholder="Query*"                  
                  id="message"
                  icon={<BsFillPatchQuestionFill />}
                  iconColor={errors.message ? "danger.100" : "primary.500"}
                  touched={touched.message}
                  errors={errors.message}  
                  lessMB
                  textArea  
                  rows={4}                  
                />              
                <Button
                  variant="success"
                  width="14rem"                  
                  px="2rem"
                  py="1rem"
                  type="submit"
                  ml="auto"
                  mt="3rem"
                  fontSize="1.6rem"
                  borderRadius="0.5rem"
                  loading={loading}
                >                  
                  Submit                 
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}