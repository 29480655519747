import { appConstants } from "redux/constants";

const initState = {  
  popUp: true,
  error: null,  
  loading: false,
  message: null,  
  initialLoading: true, 
  header: null,
  footer: null,
  home: null,  
}

export const appReducer = (state = initState, action) => {
  switch(action.type){
    case appConstants.GET_INITIAL_DATA_REQUEST:
      return {
        ...state,
        initialLoading: true,
      }

    case appConstants.SEND_CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        initialLoading: false,
        header: action.payload?.header,
        footer: action.payload?.footer,
        home: action.payload?.home,
      }

    case appConstants.SEND_CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      }

    case appConstants.CLOSE_POP_UP:
      return {
        ...state,
        popUp: false,
      }

    case appConstants.GET_INITIAL_DATA_FAILURE:
      return {
        ...state,
        initialLoading: false,
        error: action.payload.error,
      }

    case appConstants.SEND_CONTACT_US_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case appConstants.RESET_APP:
      return {
        ...state,
        loading: false,
        error: null,
        message: null,        
      }

    case appConstants.RESET:
      return initState;

    default: return state;
  }
}