import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex, Grid, Text } from "atoms";
import { NewsCard, PageSearchLayout } from "molecules";
import { getNews } from "redux/actions";

const filters = [
  "news",
  "announcement",
  "media",
  "tender",
  "press-release",
  "student",
  "faculty"
]

export const NewsPage = () => {

  const dispatch = useDispatch();
  const { news } = useSelector(state => state.data);    

  const [ search, setSearch ] = useState("");
  const [ type, setType ] = useState("");
  const [ page, setPage ] = useState(1);  

  useEffect(() => {    
    dispatch(getNews(
      page,
      12,
      search,
      type    
    ));
  }, [page, search, type, dispatch]);

  useEffect(() => {
    setPage(1);
  }, [type]);

  return (
    <>      
      <PageSearchLayout
        heroData={{
          image: "/images/home-contact.jpg",
          breadcrumb: [
            {label: "Home", url: "/"},
            {label: "Exam Schedule", url: "/academics/exam-schedule"}
          ]
        }}
        search={search}
        setSearch={setSearch}
        totalCount={news?.meta?.pagination?.total}
        resultPerPage={news?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No news found"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"  
          style={{ gap: "1rem" }}  
          mb="4rem"      
        >
          {filters.map((filter, index) => (
            <Box
              key={index}
              onClick={() => setType(filter)}
              color={type === filter ? "white" : "primary.500"}
              bg={type === filter ? "primary.500" : "white"}
              border="1px solid"
              borderColor="primary.500"
              borderRadius="0.4rem"
              p="0.7rem 2rem"
              cursor="pointer"
              transition="all 300ms ease-in-out"
              _hover={{ color: "white", bg: "primary.500" }}
            >
              <Text
                as="span"
                fontSize="1.4rem"
                fontWeight="400"
                textTransform="uppercase"
                textAlign="center"
              >
                {filter}
              </Text>
            </Box>
          ))}
          <Box             
            onClick={() => setType("")}
            bg={"primary.500"}
            color={"white"}
            border="1px solid"
            borderColor="primary.500"
            borderRadius="0.4rem"
            p="0.7rem 2rem"
            cursor="pointer"
            transition="all 300ms ease-in-out"
            _hover={{ bg: "white", color: "primary.500" }}
          >
            <Text
              as="span"
              fontSize="1.4rem"
              fontWeight="400"
              textTransform="uppercase"
              textAlign="center"
            >
              CLEAR
            </Text>
          </Box>
        </Flex>

        <Box flex="1" mb="2rem">
          <Grid            
            gridTemplateColumns={{xs: "1fr", md: "1fr 1fr"}}
            gridGap="2rem"                                         
          >
            {news?.data?.map((news) => (
              <NewsCard
                key={news?.id}
                image={news?.attributes?.image?.data?.attributes?.url}
                title={news?.attributes?.title}
                timestamp={news?.attributes?.createdAt}
                tag={news?.attributes?.type}
                slug={news?.attributes?.slug}
              />
            ))}          
          </Grid>         
        </Box>        
      </PageSearchLayout>
    </>
  )
}