import React from "react";

import { Flex } from "atoms";
import MemoColorLogo from "assets/icons/ColorLogo";

export const InitialLoader = () => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      bg="rgba(255, 255, 255, 0.8)"
    >
      <MemoColorLogo
        width="28rem"
        height="28rem"
      />      
    </Flex>
  )
}