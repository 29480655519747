import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Flex, Image, Text } from "atoms";
import { generatePublicURL } from "utils/utilities";

export const HomeReviewSlider = ({reviews}) => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,      
    arrows: false,
  };  

  return (
    <Box
      width="100%"
      height="fit-content"      
    >
      <Text 
        pb="2rem"        
        mb="4rem"
        fontSize="2.4rem"
        className="heading-border" 
        fontWeight="600"       
      >
        What People Say
      </Text>
      {/* SLIDER */}
      <Slider {...settings}>
        {reviews?.map((review) => (
          <Flex
            key={review?.id}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%" 
            height="fit-content"             
          >
            <Box>
              <Text
                fontSize={{xs: "2.5rem", md: "3.5rem"}}
                fontWeight="500"
                textAlign="center"
                color="primary.500"                
              >
                "{review?.message}"
              </Text>
            </Box>
            <Box
              width="100%"
              mx="auto"
              mt="4rem"
            >
              <Box
                width="15rem"
                height="15rem"
                mx="auto"
              >
                <Image
                  src={generatePublicURL(review?.image?.url)}
                  alt={review?.name}
                  width="100%"
                  height="100%"
                  objectFit="cover" 
                  borderRadius="50%"             
                />
              </Box>
              <Text
                textAlign="center"
                fontSize={{xs: "1.4rem", md: "1.8rem"}}
                fontWeight="400"
                color="grey.300"
                mt="1rem"
              >
                {review.name} | {review.designation}
              </Text>
            </Box>            
          </Flex>
        ))}
      </Slider>
    </Box>
  )
}