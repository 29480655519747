import { Box, Flex, HeroSection, Image, Text } from "atoms";
import { SyllabusCard, ResultCard } from "molecules";
import { formatDate, generatePublicURL } from "utils/utilities";

export const NewsDetailsPage = ({
  news
}) => {
  return (
    <Box>              
      <HeroSection
        image={generatePublicURL(news?.image?.data?.attributes?.url)}
        breadcrumb={[
          {label: "Home", url: "/"},
          {label: "News", url: "/news"},
          {label: "News Details", url: `/news/${news?.slug}`}
        ]}
      />
      <Box
        maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
        m="5rem auto"   
        py={{xm: "3rem"}}   
        as="article"      
      > 
        <Text
          fontSize={{xs: "2rem", md: "3rem"}}
          fontWeight="500"
          mb="1.5rem"
          color="primary.500"
          textAlign="center"
        >
          {news?.title}
        </Text>      
        <Text
          fontSize={{xs: "1.4rem", md: "1.6rem"}}
          fontWeight="400"
          mb="1.5rem"
          color="grey.300"
          textAlign="center"
          textTransform="uppercase"
        >
          {news?.type} | {formatDate(news?.createdAt)}
        </Text>

        <Box          
          width={{xs: "90%", md: "80%", xm: "60%"}} 
          height="100%"      
          mx="auto"   
          mb="4rem"
        >
          <Image
            src={generatePublicURL(news?.image?.data?.attributes?.url)}
            alt={news?.title}
            width="100%"
            height="100%"
            objectFit="cover"            
          />
        </Box>
        {news?.description?.split("<br/>").map((para, index) => (
          <Text
            fontSize={{xs: "1.4rem", md: "1.6rem"}}
            fontWeight="400"
            mb="1.5rem"
            color="grey.300"
            textAlign="center"
            key={index}            
            className="text-justify"
          >
            {para}
          </Text>
        ))}

        <Flex
          maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
          m="2rem auto"
          style={{gap: "1rem"}}
          flexDirection={{xs: "column", md: "row"}}
        >
          {news?.docs?.length > 0 && <SrcDiv
            title="Related Documents"
          >          
            {news.docs?.map((src) => (                   
              <SyllabusCard
                key={src?.id}
                title={src?.title}
                href={generatePublicURL(src?.data?.data?.attributes?.url)}
              />                             
            ))}
          </SrcDiv>}
          {news?.links?.length > 0 && <SrcDiv
            title="Related Links"
          >
            {news.links?.map((src) => (
              <ResultCard
                key={src?.id}
                title={src?.title}
                href={src?.url}                        
              />
            ))}
          </SrcDiv>}
        </Flex>       
      </Box>      
    </Box>
  )
}

const SrcDiv = ({
  title,
  children
}) => (
  <Box
    boxShadow="0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.1)"
    p="2rem"
    flex="1"
    borderRadius="0.5rem"
    width="100%"
  >
    <Text
      fontSize={{xs: "1.6rem", md: "2rem"}}
      fontWeight="500"
      mb="1.2rem"
      color="primary.500"
    >
      {title}
    </Text>
    {children}   
  </Box>
)