import { useEffect } from "react";

import SEO from "seo/SEO";
import { SyllabusPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Syllabus = () => {
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Download Syllabus | Soarghaati Law College, Pithoragarh"
        description="Download Syllabus of Soarghaati Law College, Pithoragarh"
        keywords="Download Syllabus SGLC, Download Syllabus SG Law College, Download Syllabus Soarghaati Law College, Pithoragarh, SDownload Syllabus Soarghaati Law College"        
      />   
      <SyllabusPage/>
    </>
  )
}

export default Syllabus;