import { Box, Flex, Grid, Image, Text } from "atoms";
import { GetLink, SyllabusCard, ResultCard } from "molecules";

export const ConferenceWorkshopCard = ({
  title,
  description,
  date,
  mode,
  time,
  image,
  url,
  venue,
  type,
  docs,
  links
}) => {
  return (
    <Grid
      gridTemplateColumns={{xs: "1fr", md: "20rem 1fr", lg: "20rem 1fr"}}
      width="100%"   
      boxShadow="0 0.2rem 0.4rem rgba(0, 0, 0, 0.25)"
      borderRadius="0.4rem"      
    >
      <Box
        minWidth={{xs: "15rem", xm: "20rem"}}        
        borderRadius={{xs: "0.4rem 0.4rem 0 0", md: "0.4rem 0 0 0.4rem"}}
        overflow="hidden"
      >
        <Image
          src={image}
          alt={title}
          width="100%"
          height="100%"
          objectFit="cover"                    
        />        
      </Box>
      <Flex
        flexGrow="1"
        py="1rem"
        px={{xs: "1.5rem", xm: "2rem"}}
        flexDirection="column"
        justifyContent="space-between"
        minHeight={{ xs: "15rem", xm: "20rem" }}        
      >
        <Box>
          <Text
            textTransform="uppercase"
            fontSize={{ xs: "1rem", md: "1.2rem" }}
            fontWeight="400"
            color="secondary.500"            
          >
            {type} |
            <Text
              as="span"
              fontSize={{ xs: "1rem", md: "1.2rem" }}
              fontWeight="500"
              color="secondary.500"
              ml="0.5rem"
              textTransform="none"
            >
              {date} - {time}
            </Text>
          </Text>
          <Text            
            fontSize={{ xs: "1.4rem", md: "1.6rem" }} 
            fontWeight="500"
            _hover={{ color: "primary.500" }}
            my="0.5rem"
          >
            {title}
          </Text>
          <Text
            fontSize={{ xs: "1.2rem", md: "1.4rem" }}
            fontWeight="400"
            color="grey.300"
            className="text-justify"                        
          >
            {description}
          </Text>
        </Box>
        {docs?.length > 0 && <Flex
          flexDirection="column"
          style={{gap: "1rem"}}
          my="1rem"
        >
          {docs?.map((doc) => (
            <SyllabusCard
              key={doc?.id}
              title={doc?.title}
              href={doc?.url}
            />
          ))}
        </Flex>}
        {links?.length > 0 && <Flex
          flexDirection="column"
          style={{gap: "1rem"}}
          mb="1rem"
        >
          {links?.map((link) => (
            <ResultCard
              key={link?.id}
              title={link?.title}
              href={link?.url}
            />
          ))}
        </Flex>}
        <Box
          mt="2rem"
        >
          <Text
            textTransform="uppercase"
            fontSize={{ xs: "1.2rem", md: "1.4rem" }}
            fontWeight="400"
            color="accent.500"
          >
            {mode}{" > "}
            {mode === "online" ? <Text
              as="span"
              fontSize={{ xs: "1.2rem", md: "1.4rem" }}
              fontWeight="500"
              color="accent.500"
              ml="0.5rem"
            >
              <GetLink
                url={url || "#"}                
              >
                Click here to Attend
              </GetLink>
            </Text> : <Text
              as="span"
              fontSize={{ xs: "1.2rem", md: "1.4rem" }}
              fontWeight="500"
              color="accent.500"
              ml="0.5rem"
            >
              {venue}
            </Text>}
          </Text>
        </Box>
      </Flex>
    </Grid>
  )
}