import { Box, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const GalleryCard = ({
  image,
  title,
  items,
  slug,
  timestamp
}) => {
  return (
    <GetLink
      url={`/album/${slug}`}
    >
      <Box
        width={{xs: "15rem", md: "20rem", xm: "30rem"}}
      >
        <Box
          width={{xs: "15rem", md: "20rem", xm: "30rem"}}
          height={{xs: "15rem", md: "20rem", xm: "30rem"}}
          borderRadius="0.4rem"        
        >
          <Image
            src={image}
            alt={title}
            width="100%"
            height="100%"
            objectFit="cover"
            borderRadius="0.4rem"
          />
        </Box>
        <Text
          mt="1rem"
          fontSize={{xs: "1.6rem", md: "2rem"}}
          fontWeight="500"
          color="grey.300"
        >
          {title}
        </Text>
        <Text
          fontSize={{xs: "1.2rem", md: "1.4rem"}}
          fontWeight="400"
          color="grey.100"
        >
          {items} items
        </Text>
        <Text
          mt="0.5rem"
          fontSize={{xs: "1rem", md: "1.2rem"}}
          fontWeight="400"
          color="grey.100"
        >
          {timestamp}
        </Text>
      </Box>
    </GetLink>
  )
}