import { useEffect } from "react";

import SEO from "seo/SEO";
import { ExamSchedulePage } from "templates";
import { scrollToTop } from "utils/utilities";

const ExamSchedule = () => {
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Download Exam Schedules | Soarghaati Law College, Pithoragarh"
        description="Download Exam Schedules of Soarghaati Law College, Pithoragarh"
        keywords="Download Exam Schedules SGLC, Download Exam Schedules SG Law College, Download Exam Schedules Soarghaati Law College, Pithoragarh, SDownload Exam Schedules Soarghaati Law College"        
      />   
      <ExamSchedulePage />
    </>
  )
}

export default ExamSchedule;