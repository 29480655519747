import { Box, Flex, Grid, Text } from "atoms";
import { EventCard, GetLink, NewsCard } from "molecules";

export const HomeNewsEvents = ({
  news,
  events
}) => {
  return (
    <Box
      maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
      mx="auto"
      mb="4rem"
    >
      <Text 
        pb="2rem"        
        mb="2rem"
        fontSize="2.4rem"
        className="heading-border"
        fontWeight="600"               
      >
        News and Events
      </Text>
      <Flex
        flexDirection={{xs: "column", md: "row"}}        
      >
        {news?.length > 0 && <Box flex="1" mb="2rem">
          <Grid            
            gridTemplateColumns={{xs: "1fr", md: "1fr", lg: "1fr 1fr"}}
            gridGap={{xs: "2rem", xm: "3rem", lg: "4rem"}}                 
            pb="4rem"
            borderBottom="1px solid"
            borderColor="primary.500"
          >
            {news?.map((news) => (
              <NewsCard
                key={news?.id}
                image={news?.image?.url}
                title={news?.title}
                timestamp={news?.createdAt}
                tag={news?.type}
                id={news?.id}              
                slug={news?.slug}                
              />
            ))}          
          </Grid>
          <GetLink url="/news">
            <Text              
              fontSize="1.4rem"
              fontWeight="500"
              _hover={{color: "primary.500"}}
              textTransform="uppercase"              
            >
              View All
            </Text>
          </GetLink>
        </Box>}
        {events?.length > 0 && <Box flex={{xs: "1", md: "0.4"}} ml={{xs: "0", md: "2rem"}}  mb="2rem">
          <Grid                        
            gridTemplateColumns="1fr"
            gridGap="2rem"
            pb="4rem"
            borderBottom="1px solid"
            borderColor="primary.500"
          >
            {events?.map((event) => (
              <EventCard
                key={event?.id}
                timestamp={event?.date}
                title={event?.title}
                slug={event?.slug}
              />
            ))}
          </Grid>
          <GetLink url="/events">
            <Text              
              fontSize="1.4rem"
              fontWeight="500"
              _hover={{color: "primary.500"}}
              textTransform="uppercase"              
            >
              View All
            </Text>
          </GetLink>
        </Box>}
      </Flex>
    </Box>
  )
}