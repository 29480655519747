import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Text } from "atoms";
import { getEventDetails } from "redux/actions";
import SEO from "seo/SEO";
import { EventDetailsPage } from "templates";
import { deSlugAndCapitalize, scrollToTop } from "utils/utilities";

const EventDetails = () => { 
  
  const { slug } = useParams();
  
  const dispatch = useDispatch();
  const { eventDetails } = useSelector(state => state.data);
  
  useEffect(() => {
    scrollToTop(window)
    if(slug !== eventDetails?.attributes?.slug) dispatch(getEventDetails(slug));
  }, [dispatch, slug, eventDetails])

  return (
    <> 
      <SEO
        title={`${eventDetails?.attributes?.slug === slug ?  eventDetails?.attributes?.title : deSlugAndCapitalize(slug)} | Soarghaati Law College, Pithoragarh`}
        description={eventDetails?.attributes?.description?.split("<br/>")[0]?.slice(0, 160)}
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/event/${slug}`}
      />
      {eventDetails?.attributes?.slug !== slug ?
        <Text bg="dark.100" color="white" py="3rem" textAlign="center">Event Not Found</Text>                
      : <EventDetailsPage
        event={eventDetails?.attributes}
      />}                 
    </>
  )
}

export default EventDetails;