import { useEffect } from "react";

import SEO from "seo/SEO";
import { ConferenceWorkshopsPage } from "templates";
import { scrollToTop } from "utils/utilities";

const ConferenceWorkshops = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Conference & Workshops | Soarghaati Law College, Pithoragarh"
        description="Conference & Workshops of Soarghaati Law College, Pithoragarh"
        keywords="Conference & Workshops SGLC, Conference & Workshops SG Law College, Conference & Workshops Soarghaati Law College, Pithoragarh, SConference & Workshops Soarghaati Law College"        
      />  
      <ConferenceWorkshopsPage/>       
    </>
  )
}

export default ConferenceWorkshops;