import { Box, Flex, HeroSection, Image, Text } from "atoms";
import { formatDate, generatePublicURL } from "utils/utilities";

export const AlbumDetailsPage = ({
  album
}) => {
  return (
    <Box>          
      <HeroSection
        image={generatePublicURL(album?.thumbnail?.data?.attributes?.url) || "/images/home-contact.jpg"}
        breadcrumb={[
          {label: "Home", url: "/"},
          {label: "Albums", url: "/album"},
          {label: "Album Details", url: "/album/"+album?.slug}
        ]}
      />
      <Box
        maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
        m="5rem auto"   
        py={{xm: "3rem"}}         
      >
        <Box>
          <Text
            textAlign="center"
            fontSize={{xs: "1.8rem", md: "2.4rem"}}
            fontWeight="400"
            color="primary.500"
            mb="2rem"
          >
            {formatDate(album?.createdAt)}
          </Text>
        </Box>
        <Flex
          style={{gap: "0.4rem"}}
          flexWrap="wrap"
          justifyContent="center"
        >
          {album?.images?.data?.map((image, index) => (
            <Box
              key={image?.id}
              maxWidth="30rem"
            >
              <Image
                src={generatePublicURL(image?.attributes?.url)}
                alt={album?.title+"-image-"+String(index)}
                width="100%"
                height="100%"
                objectFit="cover"
                preview={true}
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}