import { useEffect, useState } from "react";
import { IoMdArrowDropright } from "react-icons/io"
import { RiCloseFill, RiMenu2Line } from "react-icons/ri";
import { useSelector } from "react-redux";

import MemoLogoInline from "assets/icons/LogoInline";
import MemoLogo from "assets/icons/Logo";
import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { generatePublicURL } from "utils/utilities";

export const Header = () => {

  const { header } = useSelector(state => state.app);

  const [scroll, setScroll] = useState();
  const [ menu, setMenu ] = useState(false);
  const [ selectedLink, setSelectedLink ] = useState(null);
  const [ selectedNav, setSelectedNav ] = useState(null);

  const handleScroll = () => {
    if (window.scrollY >= 160) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <>
      <Box 
        as="header"
        position="fixed"
        width="100%"
        top="0"
        zIndex="99"
      >
        {/* HEADER */}
        <Flex
          minWidth="100%"
          height={{xs: "7rem", md: "9.6rem"}}          
        >
          {/* HEADER LEFT */}
          <Box
            width={{xs: "7rem", md: "14rem", lg: "17rem"}}
            bg={menu ? "dark.100" : scroll ? "primary.500" : "transparent"}
            transition="all 0.3s ease-in-out"
          >
            <Flex
              alignItems="center"
              justifyContent={{xs: "center", md: "unset"}}
              height="100%"
              width="100%"
              color={menu ? "primary.500" : "white"}
              pl={{ xs: "0", md: "6rem", xm: "8rem", lg: "10rem" }}              
              transition="color 0.3s ease-in-out"
            >
              {menu ? <RiCloseFill
                fontSize="3.5rem"
                style={{cursor: "pointer"}}   
                onClick={() => setMenu(!menu)}
              /> 
              : <RiMenu2Line
                fontSize="3.5rem"
                style={{cursor: "pointer"}}   
                onClick={() => setMenu(!menu)}            
              />}
            </Flex>    
          </Box>
          {/* HEADER RIGHT */}
          <Box 
            flex="flex-grow"
            width="100%"
            bg={menu ? "dark.100" : scroll ? "dark.100" : "transparent"}
            transition="all 0.3s ease-in-out"
          >
            <Flex
              alignItems="center"
              justifyContent={(scroll || menu) ? "space-between" : "flex-end"}
              height="100%"
              width="100%"
              pr={{xs: "1rem", md: "6rem", xm: "8rem", lg: "10rem"}}
            >
              {/* LOGO */}
              {(scroll || menu) && <Box
                width={{xs: "15.5rem", md: "22rem"}}
                height="auto"
                ml="2rem"
              >
                <GetLink url="/" onClick={() => setMenu(false)}>
                  <MemoLogoInline
                    width="100%"
                    height="100%"
                  />
                </GetLink>
              </Box>}
              {/* BTN */}              
              {header?.button && <GetLink               
                url={header?.button?.url}
              >
                <Button
                  variant="white"
                  p={{xs: "0.5rem 1rem", md: "0.8rem 1.5rem"}}
                  borderRadius="0.5rem"
                  width="fit-content"
                  height="fit-content"
                >
                  {header?.button?.title}
                </Button>    
              </GetLink>}          
            </Flex>
          </Box>
        </Flex>

        {/* NAVBAR */}
        <Box
          as="nav"
          display={menu ? "block" : "none"}
          position="absolute"
          width="100%"
          height="calc(100vh - 9.6rem)"        
          top={{xs: "7rem", md: "9.6rem"}}
          zIndex="99"          
          transition="all 0.3s ease-in-out"        
        >          
          <Flex
            width="100%"
            height="100%"
            flexDirection={{xs: "column", md: "row"}}           
          >
            {/* NAVBAR LEFT */}
            <Box
              flex="1"
              bg="primary.500"
              px={{xs: "3rem", md: "6rem", lg: "10rem"}}
              py={{xs: "3rem", md: "7rem", lg: "10rem"}}
              overflowY="auto"
              className="hide-scrollbar"
              maxWidth={{xs: "100%", md: "60rem"}}
            >
              {header?.leftNav?.map((item) => (
                <Box
                  key={item.id}
                  color="white"                                    
                  borderBottom="1px solid"
                  borderColor="whitesmoke"
                >
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    pt={{xs: "1rem", md: "1.6rem", lg: "2.5rem"}}
                    pb={{xs: "1rem", md: "1rem", lg: "2.5rem"}}
                    px="1rem"  
                    cursor="pointer"   
                    onClick={() => {
                      if(selectedLink === item?.id){
                        setSelectedLink(null);
                        setSelectedNav(null);
                        return;
                      }
                      setSelectedLink(item?.id);
                      setSelectedNav(item);
                    }}                  
                  >
                    <Text
                      fontSize={{xs: "1.8rem", md: "2rem"}}
                      fontWeight="600"
                    >
                      {item?.title}
                    </Text>
                    <Box
                      fontSize={{xs: "2rem", md: "2.4rem"}}                      
                      mt="0.2rem"                                                               
                      transform={selectedLink === item.id ? "rotate(90deg)" : "rotate(0deg)"}
                      transition="all 0.2s ease-in-out"
                    >
                      <IoMdArrowDropright/>
                    </Box>
                  </Flex>                  
                  {selectedLink === item.id && (
                    <Box
                      display={{xs: "block", lg: "none"}} 
                      px="1rem"   
                      pb="1rem"                        
                    >
                      <Text
                        fontSize={{xs: "1.4rem", md: "1.6rem"}}
                        mt="0.5rem"
                        mb="1rem"
                      >
                        {item?.description}
                      </Text>
                      {item?.children?.map((child) => (
                        <Box
                          key={child.id}
                          py={{xs: "0.5rem", md: "0.8rem"}}                          
                        >
                          <GetLink
                            className={(navData) => navData.isActive ? "active-primary" : "" }                            
                            url={child?.url}
                            onClick={() => setMenu(false)}
                            type="nav"
                          >        
                            <Text
                              fontSize={{xs: "1.6rem", md: "1.8rem"}}
                            >
                              {child?.title}
                            </Text>                    
                          </GetLink>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            {/* NAVBAR CENTER */}
            <Box
              display={{xs: "none", lg: "block"}}
              flex="1"              
            >
              {selectedNav ? <Box
                bg="white"
                p="8rem 3.5rem"
                height="100%"
                width="100%"
              >              
                <Text
                  fontSize={{xs: "1.8rem", md: "2rem"}}
                  fontWeight="600"
                  mb="1.5rem"
                  color="primary.500"
                >
                  {selectedNav?.title}
                </Text>           
                <Text
                  fontSize={{xs: "1.4rem", md: "1.6rem"}}
                  color="grey.500"
                >
                  {selectedNav?.description}
                </Text> 
                <Box
                  my="2rem"
                  borderBottom="1px solid"
                  borderColor="grey.300"
                />  
                <Flex
                  flexDirection="column"       
                  color="grey.300"
                >
                  {selectedNav?.children?.map((item) => (
                    <Box
                      key={item?.id}
                      mb="1rem"                      
                      transition="color 300ms ease-in-out"
                      fontWeight="500"
                      _hover={{
                        color: "primary.500"
                      }}
                    >
                      <GetLink
                        className={(navData) => navData.isActive ? "active-primary-lg" : "" }
                        url={item?.url}
                        type="nav"
                        onClick={() => setMenu(false)}
                      >
                        <Text>
                          {item?.title}
                        </Text>
                      </GetLink>
                    </Box>
                  ))}
                </Flex>
              </Box> : <Box
                width="100%"
                height="100%"
                display={selectedNav ? "none" : "block"}
              >
                <Text
                  position="absolute"
                  right="5%"
                  top="10%"  
                  left="5%"                
                  zIndex="1"
                  fontSize={{xs: "2rem", md: "2.5rem"}}
                  fontWeight="600"
                  color="white"                        
                >
                  {header?.quotes[Math.floor(Math.random() * header?.quotes.length)]?.value}
                </Text>
                <Image
                  src={generatePublicURL(header?.quotesBgImage?.url)}
                  alt="header"
                  width="100%"
                  height="100%"
                  objectFit="cover"
                />
              </Box>}
            </Box>
            {/* NAVBAR RIGHT */}
            <Box
              flex={{md: "0.8"}}
              height={{xs: "fit-content", md: "100%"}}
              bg="grey.50"
              px={{xs: "3rem"}}
              pt={{xs: "3rem", md: "7rem", lg: "10rem"}}
              pb={{xs: "6rem", md: "10rem"}}
              color="grey.300"
            >
              <Flex
                flexDirection="column"                
              >
                {header?.rightNav?.map((item) => (
                  <Box
                    key={item?.id}
                    mb="1rem"
                    onClick={() => setMenu(false)}
                    transition="color 300ms ease-in-out"
                    fontWeight="500"
                    _hover={{
                      color: "secondary.500"
                    }}
                  >
                    <GetLink
                      className={(navData) => navData.isActive ? "active-secondary" : "" }                      
                      url={item?.url}
                      type="nav"
                    >
                      <Text>
                        {item?.title}
                      </Text>
                    </GetLink>
                  </Box>
                ))}
              </Flex>
              {/* LOGO ABSOLUTE */}
              <Box
                position="absolute"
                width="20rem"
                height="auto"
                right="-8rem"
                bottom="25%"
                display={{xs: "none", md: "block"}}
              >
                <MemoLogo
                  width="100%"
                  height="100%"
                  color="#B3B3B3"
                />
              </Box>
            </Box>
          </Flex>
        </Box>  
      </Box>          
    </>
  );
};